import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import casinoHotels from './data/casinoHotels';
import Contacts from './components/Contacts';

const HomePage = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto p-4 mt-24 mb-20">
        <header className="text-center my-8">
          <h1 className="text-4xl font-bold">List of Best Casino Hotels to Visit in Australia</h1>
          <p className="mt-4 text-lg">Explore the finest casino hotels in Australia, combining luxury accommodations with top-notch entertainment and gaming experiences.</p>
        </header>

        <section className="my-8">
          <h2 className="text-3xl font-semibold mb-4">Top Casino Hotels</h2>
          <div className="grid grid-cols-1 gap-8">
            {casinoHotels.map((hotel, index) => (
              <div key={index} className="border rounded-lg p-4 shadow-md bg-white">
                <div className="flex flex-col md:flex-row">
                  <img src={hotel.image} alt={hotel.name} className="w-full md:w-1/3 h-48 object-cover rounded-md" />
                  <div className="p-4 md:w-2/3">
                    <div className="flex items-center justify-between">
                      <h3 className="text-2xl font-semibold">{hotel.name}</h3>
                      <div className="flex items-center">
                        <span className="bg-blue-500 text-white rounded-full px-2 py-1 mr-2">{hotel.rating}</span>
                        <span className="text-gray-500">{hotel.reviews} Reviews</span>
                      </div>
                    </div>
                    <p className="text-gray-600 mt-2">{hotel.description}</p>
                    <div className="flex items-center justify-between mt-4">
                      <div className="text-orange-600 font-bold">From {hotel.price}</div>
                      <a href={hotel.link} className="bg-orange-600 text-white py-2 px-4 rounded">Check Availability</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <Contacts />
      <Footer />
    </>
  );
};


export default HomePage;
