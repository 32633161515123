import React from "react";
import { Link } from "react-scroll";

export default function Header() {
  return (
    <header className="bg-green-800 fixed w-full top-0 shadow-md">
      <nav className="flex justify-between items-center py-4 px-8">
        <div className="flex items-center justify-between w-full text-white">
          <a href="/" className="text-2xl font-bold">
            Australian Tours Travel Agency
          </a>
          <ul className="flex ml-10 space-x-4 text-sm font-bold">
            <li>
              <a href="/" className="hover:text-gray-300">Home</a>
            </li>
            <li>
              <a href="/hotels" className="hover:text-gray-300">Casino Hotels</a>
            </li>
            <li>
              <Link
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70} // Adjust this value if needed to align with your design
                duration={500}
                className="bg-green-900 p-2 rounded text-white cursor-pointer hover:bg-green-700"
              >
                Get In Touch With Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
