import React from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import casinoHotels from './data/casinoHotels';
import { Button } from '@headlessui/react';
import Contacts from './components/Contacts';

const HomePage = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto mt-24 mb-20 p-4">
        <header className="text-center my-8">
          <h1 className="text-4xl font-bold">Best Casino Hotels to Visit in Australia</h1>
          <p className="mt-4 text-lg">
            Explore an exclusive selection of Australia’s premier casino hotels, where luxury, entertainment, and top-tier amenities converge for an unforgettable experience.
          </p>
          <img className="w-full mt-5" src="/assets/img-11.jpeg" alt="hotels" />
        </header>

        <section className="my-8">
          <h2 className="text-3xl font-semibold mb-4">In a Rush?</h2>
          <p className="mb-4">Browse our curated list of top-rated hotels in Sydney, renowned for their outstanding accommodations and exceptional services:</p>
          <table className="w-full text-left table-auto border-collapse bg-slate-50 rounded my-10 shadow-md">
            <thead className='bg-slate-200'>
              <tr>
                <th className="p-4">Hotel Name</th>
                <th className="p-4">Accommodation Rating</th>
                <th className="p-4">Price, $</th>
                <th className="p-4">Availability</th>
              </tr>
            </thead>
            <tbody>
              {casinoHotels.map((hotel, index) => (
                <tr key={index} className="border-t">
                  <td className="px-4 py-2">{hotel.name}</td>
                  <td className="px-4 py-2">{hotel.rating} <svg className="inline-block" xmlns="http://www.w3.org/2000/svg" height="14px" width="14px" version="1.1" id="Capa_1" viewBox="0 0 47.94 47.94" >
<path fill="#ED8A19" d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757  c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042  c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685  c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528  c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956  C22.602,0.567,25.338,0.567,26.285,2.486z"/>
</svg></td>
                  <td className="px-4 py-2">{hotel.price}</td>
                  <td className="px-4 py-2">
                    <a href={hotel.link} className="text-primary underline">Check Availability</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button as="a" href="/hotels" className="font-bold rounded bg-primary py-2 px-4 text-sm text-white data-[hover]:bg-sky-500 data-[hover]:data-[active]:bg-sky-700 transition-all ease-in-out duration-150">
            See More
          </Button>
        </section>

        <section className="my-8">
          {infoBlocks.map((block, index) => (
            <div key={index} className="flex flex-col lg:flex-row items-center my-20">
              {index % 2 === 0 ? (
                <>
                  <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
                  <div className="p-6 lg:w-1/2">
                    <h3 className="text-2xl font-semibold mb-4">{block.title}</h3>
                    <p className="text-gray-600">{block.description}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="p-6 lg:w-1/2">
                    <h3 className="text-2xl font-semibold mb-4">{block.title}</h3>
                    <p className="text-gray-600">{block.description}</p>
                  </div>
                  <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
                </>
              )}
            </div>
          ))}
        </section>
      </div>
      <Contacts />
      <Footer />
    </>
  );
};

const infoBlocks = [
  {
    title: 'About Australia',
    description: 'Australia is renowned for its breathtaking landscapes, diverse ecosystems, and dynamic urban centers. From the natural splendor of the Great Barrier Reef to the rugged beauty of the Outback, this vast nation offers a wealth of unique experiences. Australia’s cities are hubs of culture, cuisine, and commerce, reflecting a blend of modernity and tradition. With a reputation for friendly locals and a laid-back lifestyle, Australia is an inviting destination for travelers seeking both adventure and relaxation. The country’s varied climate, from the tropical north to the temperate south, ensures that there is always something exciting to discover.',
    image: '/assets/img-5.jpg'
  },
  {
    title: 'Major Cities',
    description: 'Australia’s major cities, including Sydney, Melbourne, Brisbane, and Perth, each offer distinct attractions and experiences. Sydney, with its iconic Opera House and Harbour Bridge, is a global city known for its vibrant cultural scene and stunning beaches. Melbourne is celebrated as Australia’s cultural capital, renowned for its coffee culture, artistic heritage, and major sporting events. Brisbane offers a relaxed lifestyle with a strong emphasis on outdoor activities and natural beauty. Perth, often called the world’s most isolated city, boasts a rich history and beautiful coastline. These cities exemplify the diverse urban tapestry that makes Australia a fascinating destination.',
    image: '/assets/img-6.jpg'
  },
  {
    title: 'Luxury Hotels',
    description: 'Australia hosts an array of luxury hotels that provide guests with unparalleled service, exquisite dining, and first-class amenities. These hotels are strategically located to offer breathtaking views and convenient access to key attractions. Guests can indulge in world-class spas, state-of-the-art fitness centers, and gourmet restaurants. Attention to detail and personalized services ensure that every stay is memorable. Whether for business or leisure, the luxury hotels in Australia promise an exceptional experience, blending comfort with sophistication.',
    image: '/assets/img-7.png'
  },
  {
    title: 'Casino Hotels',
    description: 'Australia’s top casino hotels combine luxury accommodation with world-class gaming facilities and entertainment. These establishments offer a comprehensive array of gaming options, including slot machines, table games, and exclusive poker rooms. Guests can also enjoy live shows, fine dining, and VIP services, all within the opulent surroundings of their hotel. The vibrant and dynamic atmosphere of these casino hotels provides an exciting backdrop for both gaming enthusiasts and casual visitors. With their high standards of service and entertainment, Australia’s casino hotels ensure a thrilling and enjoyable stay.',
    image: '/assets/img-8.jpg'
  }
];

export default HomePage;
