
const casinoHotels = [
  {
    name: 'Crown Towers Melbourne',
    description: 'Crown Towers Melbourne offers unparalleled luxury and entertainment with spacious rooms, fine dining, and an extensive casino floor.',
    image: '/assets/img-8.jpg',
    link: 'https://www.crownmelbourne.com.au/',
    rating: '9.4',
    reviews: '1500',
    price: '$300 per night'
  },
  {
    name: 'The Star Sydney',
    description: 'Located in the heart of Sydney, The Star offers a world-class casino experience with stunning views, luxurious rooms, and gourmet dining.',
    image: '/assets/img-9.webp',
    link: 'https://www.star.com.au/sydney/',
    rating: '9.2',
    reviews: '2000',
    price: '$280 per night'
  },
  {
    name: 'Jupiters Hotel & Casino Gold Coast',
    description: 'Jupiters Hotel & Casino provides the ultimate Gold Coast experience with its premium accommodations, diverse dining options, and vibrant casino floor.',
    image: '/assets/img-7.png',
    link: 'https://www.star.com.au/goldcoast/',
    rating: '8.8',
    reviews: '1200',
    price: '$250 per night'
  },
  {
    name: 'Crown Perth',
    description: 'Crown Perth combines luxury and excitement with its spacious rooms, exquisite restaurants, and expansive casino facilities.',
    image: '/assets/img-10.avif',
    link: 'https://www.crownperth.com.au/',
    rating: '9.0',
    reviews: '1100',
    price: '$270 per night'
  },
  {
    name: 'The Star Gold Coast',
    description: 'The Star Gold Coast offers an unparalleled experience with its luxury accommodations, diverse dining options, and a state-of-the-art casino.',
    image: '/assets/img-4.webp',
    link: 'https://www.star.com.au/goldcoast/',
    rating: '9.1',
    reviews: '1600',
    price: '$290 per night'
  },
  {
    name: 'SkyCity Adelaide',
    description: 'SkyCity Adelaide is a premier destination offering luxury accommodations, fine dining, and an exciting casino experience in a historic setting.',
    image: '/assets/img-5.jpg',
    link: 'https://www.skycityadelaide.com.au/',
    rating: '8.7',
    reviews: '900',
    price: '$220 per night'
  }
];

  export default casinoHotels;
  